// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://api.kmutnb-inno.top',
  images: 'http://images.kasetsuphan.me',
  video: 'http://video.kasetsuphan.me',
  signalr: 'http://video.kasetsuphan.me/signalr'
  // url: 'https://api.homkhajorn-abd.com',
  // images: 'https://images.homkhajorn-abd.com',
  // video: 'https://video.homkhajorn-abd.com',
  // signalr: 'https://video.homkhajorn-abd.com/signalr'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
