import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './a-main-component/login/login.component';
import { HomeComponent } from './a-main-component/home/home.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from './a-main-component/navbar/navbar.component';
import { BoothComponent } from './component/booth/booth.component';
import { AdminComponent } from './component/admin/admin.component';
import { AddAdminComponent } from './component/admin/add-admin/add-admin.component';
import { AddBoothComponent } from './component/booth/add-booth/add-booth.component';
import { DetailBoothComponent } from './component/booth/detail-booth/detail-booth.component';
import { ProductBoothComponent } from './component/booth/detail-booth/product-booth/product-booth.component';
import { AddProductComponent } from './component/booth/detail-booth/product-booth/add-product/add-product.component';
import { FaqBoothComponent } from './component/booth/detail-booth/faq-booth/faq-booth.component';
import { ChatBoothComponent } from './component/booth/detail-booth/chat-booth/chat-booth.component';
import { AddFaqComponent } from './component/booth/detail-booth/faq-booth/add-faq/add-faq.component';
import { UserBoothComponent } from './component/booth/detail-booth/user-booth/user-booth.component';
import { LivestreamComponent } from './component/livestream/livestream.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ChatComponent } from './component/chat/chat.component';
import { AddUserChatComponent } from './component/chat/add-user-chat/add-user-chat.component';
import { BlockMessageComponent } from './component/block-message/block-message.component';
import { AddMessageComponent } from './component/block-message/add-message/add-message.component';
import { SettingPageComponent } from './component/setting-page/setting-page.component';
import { ScheduleventsComponent } from './component/schedulevents/schedulevents.component';
import { DemoComponent } from './component/demo/demo.component';
import { BoothButtonComponent } from './component/booth/detail-booth/booth-button/booth-button.component';
import { AddButtonComponent } from './component/booth/detail-booth/booth-button/add-button/add-button.component';
import { AddScheduleventsComponent } from './component/schedulevents/add-schedulevents/add-schedulevents.component';
import { LiveStreamComponent } from './component/livestream/live-stream/live-stream.component';
import { ProductComponent } from './component/product/product.component';
import { AddProductMainComponent } from './component/product/add-product-main/add-product-main.component';
import { ContactComponent } from './component/contact/contact.component';
import { AddContactComponent } from './component/contact/add-contact/add-contact.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    BoothComponent,
    AdminComponent,
    AddAdminComponent,
    AddBoothComponent,
    DetailBoothComponent,
    ProductBoothComponent,
    AddProductComponent,
    FaqBoothComponent,
    ChatBoothComponent,
    AddFaqComponent,
    UserBoothComponent,
    LivestreamComponent,
    ChatComponent,
    AddUserChatComponent,
    BlockMessageComponent,
    AddMessageComponent,
    SettingPageComponent,
    ScheduleventsComponent,
    DemoComponent,
    BoothButtonComponent,
    AddButtonComponent,
    AddScheduleventsComponent,
    LiveStreamComponent,
    ProductComponent,
    AddProductMainComponent,
    ContactComponent,
    AddContactComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule,


  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
