import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../shareds/services/http.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { appUrl } from '../../../app.url';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-live-stream',
  templateUrl: './live-stream.component.html',
  styleUrls: ['./live-stream.component.scss']
})
export class LiveStreamComponent implements OnInit {
  LiveId; any;
  constructor(private http: HttpService,
    private build: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.LiveId = this.activatedRoute.snapshot.params['id'];
  }

  ngOnInit() {
    this.formControl();
    if (this.LiveId) this.http.methodGet(`api/BOLiveStream/${this.LiveId}`).subscribe(res => {
      this.form.get('Name').setValue(res['ls_name'])
      this.form.get('LinkStream').setValue(res['ls_link_stream'])
      this.form.get('Detail').setValue(res['ls_detail'])
      this.form.get('LinkAddressCustom').setValue(res['ls_link_stream_custom_server'])
      this.form.get('LinkAddress').setValue(res['ls_link_address'])
      this.form.get('LinkAddressLive').setValue(res['ls_link_address_live'])
    })

  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      Name: "",
      LinkStream: "",
      LinkAddressLive: "",
      LinkAddress: "",
      LinkAddressCustom: "",
      Detail: "",
      ShowStatus: false,
      ls_isactive: true
    })
  }

  createLive() {
    this.http.methodPost(`api/BOLiveStream`, this.form.value).subscribe(res => {
      alert("สร้าง Live แล้ว")
      this.router.navigate(['/', appUrl.livestreammanage])

    })
  }

  updateLive() {
    this.http.methodPut(`api/BOLiveStream/${this.LiveId}`, this.form.value).subscribe(res => {
      alert("แก้ไข Live แล้ว")
      this.router.navigate(['/', appUrl.livestreammanage])

    })
  }

  cancel() {
    this.router.navigate(['/', appUrl.livestreammanage])
  }
}
